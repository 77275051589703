import type DataGridControl from 'o365.controls.Grid.DataGrid.ts';
import type { Location } from 'o365.modules.SelectionControl.ts';
import BaseGridNavigation from 'o365.controls.Grid.extensions.BaseNavigation.ts';

declare module 'o365.controls.Grid.DataGrid.ts' {
    interface DataGridControl {
        navigation: DataGridNavigation;
    }
}


export default class DataGridNavigation extends BaseGridNavigation<DataGridControl> {

    isPotentialCell(cell: Location) {
        const control = this._getGridControl();
        if (cell.y < 0) {
            if (!control.dataObject.hasNewRecords) {
                return false;
            }
        }

        const column = control.columns.columns[cell.x];

        return !!column;
    }

    /** Validate the row index for navigation */
    validRowIndex(rowIndex: number) {
        const control = this._getGridControl();
        if (this._getRowFromStorage(rowIndex) == null) { return false; }
        else {
            if (control.dataObject.hasNewRecords) {
                if (rowIndex < 0) {
                    if (rowIndex < control.dataObject.batchData.length*-1) { return false; }
                } else {
                    if (rowIndex >= control.dataObject.data.length) { return false; }
                }
            } else {
                if (rowIndex < 0 || rowIndex >= control.dataObject.data.length) { return false;}
            }
        }
        return true;
    }

    /** Validate the row index for navigation in edit mode */
    validEditRowIndex(rowIndex: number) {
        const control = this._getGridControl();
        const row =this._getRowFromStorage(rowIndex)
        if (row == null || row.o_groupHeaderRow) { return false; }
        return true;
    }

    save() {
        const control = this._getGridControl();
        if (!control.dataObject.disableSaveOncurrentIndexChange) {
            control.save();
        }
    }
    async copy(_e: KeyboardEvent) {
        const control = this._getGridControl();
        control.dataObject.selectionControl.copySelection(false, control.columns.columns);
        return true;
    }

    async paste(e: KeyboardEvent) {
        const control = this._getGridControl();
        return await control.dataObject.selectionControl.pasteSelection(e, control.columns.columns);
    }

    handleEsc(activeCell: any) {
        const control = this._getGridControl();
        let currentItem: any = null;
        if (activeCell) {
            currentItem =this._getRowFromStorage(activeCell.y);
        }

        if (currentItem?.hasChanges) {
            control.dataObject.cancelChanges(currentItem.index);
        }
    }

    handleCopyFromAbove(cell, activeCell) {
        const control = this._getGridControl();
        const rowAbove = this._getRowFromStorage(cell.y)
        const currentRow = this._getRowFromStorage(activeCell.y)
        const field = control.columns.columns[cell.x].field
        if (field && currentRow && rowAbove) {
            if (control.gridFocusControl.editMode) {
                currentRow[field] += rowAbove[field];
            } else {
                currentRow[field] = rowAbove[field];
                control.gridFocusControl.enterEditMode();
            }
        }
    }

    findClosestValidRowForInEditTab(row: number, increment: number, forceEditMode: boolean) {
        // const control = this._getGridControl();
        let item = this._getRowFromStorage(row);
        if (forceEditMode && !item?.isBatchRecord) { item = null; row = null }
        while (item && item['o_groupHeaderRow']) {
            row += increment;
            item = this._getRowFromStorage(row);
        }
        return row;
    }


    initializeExtension() {
        const control = this._getGridControl();
        this.selectionControl = control.dataObject.selectionControl;
        this.allowEditWatchTarget = () => control.dataObject.allowUpdate;
    }

    /** Get row from batch or base storage based on index */
    private _getRowFromStorage(index: number) {
        const control = this._getGridControl();
        if (index < 0 && control.dataObject.hasNewRecords) {
            const correctedIndex = (index*-1)-1;
            return control.dataObject.batchData[correctedIndex];
        } else {
            return control.dataObject.storage.data[index];
        }
    }
}